import {compose, withState, withHandlers, lifecycle} from "recompose";
import {connect} from "react-redux";
import View from "./View";
import {quizzesService} from "../../../_api";
import {loginTelegram} from "../../../_actions";
import Pusher from "pusher-js"
import Echo from 'laravel-echo';
import {config} from "../../../_services";

let websocket = null;
const optionsForPublicChannel = {
    broadcaster: 'pusher',
    key: config.pusherKey,
    cluster: 'mt1',
    forceTLS: true
};

const connectProxy = (code, props) => {
    websocket = new Echo(optionsForPublicChannel);
    const channel = websocket.channel(`App.User.telegramlogin.${code}`);
    const callBack =  (user) => {
        props.loginTelegram(user.data);
        props.setCodeTelegram(null);
        props.setTimer(null);
        if(null !== props.timerId){
            clearInterval(props.timerId);
        }
    };
    channel.listen('.App\\Events\\TelegramLogin', callBack.bind(this));
}

export default compose(
    connect(
        state => ({
            errorMessage: state.cpState.toJS().error,
            appLoading: state.cpState.toJS().loading
        }),
        {loginTelegram}
    ),
    withState("сodeTelegram", "setCodeTelegram", null),
    withState("timer", "setTimer", null),
    withState("timerId", "setTimerId", null),
    withState("isModalOpen", "setIsModalOpen", false),
    withHandlers({
        changeModalOpenHandler: props => () => {
            props.setIsModalOpen(!props.isModalOpen);
        },
        handleGetCode: props => () => {
            quizzesService.getСodeTelegramForLogin().then((data) => {
                connectProxy(data.code, props);
                props.setCodeTelegram(data.code);
                let timeout = data.timeout;
                const timer = () => {
                    if(timeout <= 0){
                        props.setCodeTelegram(null);
                        props.setTimer(null);
                        clearInterval(timerId);
                        return;
                    }
                    timeout = timeout - 1;
                    props.setTimer(timeout);
                }

                let timerId = setInterval(timer.bind(this), 1000);
                props.setTimerId(timerId)

            });
        },
    }),
    lifecycle({
        componentDidMount() {

        },
        componentWillUnmount() {
            if(null !== this.props.timerId){
                clearInterval(this.props.timerId);
            }
        }
    })
)(View);
